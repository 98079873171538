'use strict'

let win = $(window),
touch = false,
docHeight,
winWidth,
winHeight,
scrollpane,
fadeCoords = [],
fadeHeight = [],
_qg = {};


_qg.ua = (function(){
	
	let _ua = navigator.userAgent.toLowerCase();
	let _o = {
		ltIE6:typeof window.addEventListener == "undefined" && typeof document.documentElement.style.maxHeight == "undefined",
		ltIE7:typeof window.addEventListener == "undefined" && typeof document.querySelectorAll == "undefined",
		ltIE8:typeof window.addEventListener == "undefined" && typeof document.getElementsByClassName == "undefined",
		IE9: navigator.appVersion.toLowerCase().indexOf("msie 9.") != -1,
		IE10 : navigator.userAgent.match(/Trident\/[6]/i),
		IE11: navigator.userAgent.match(/Trident\/7\./),
		IE:document.uniqueID,
		Firefox:window.sidebar,
		Opera:window.opera,
		chrome: _ua.indexOf('chrome') > -1,
		//Webkit:!document.uniqueID && !window.opera && !window.sidebar && !window.orientation && window.localStorage,
		mobile:/android|iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()),
		iphone:/iphone|ipod/i.test(_ua),
		android:/android/.test(_ua),
		ipad:/ipad/.test(_ua),
		tablet:undefined,
		smartphone:undefined,
		touch: window.ontouchstart === null
	}
	_o.tablet = _o.ipad;
	if(!_o.tablet && _o.android){ _o.tablet = !(/mobile/.test(_ua));}				
	_o.smartphone = _o.iphone || _o.android ? true : false;
	
	let v = [];
	if (/iP(hone|od|ad)/.test(navigator.platform)) {v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);}
	let vAry = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
	_o.iosV = vAry[0] ? vAry[0] : '';
	
	return _o;
})();



$(document).ready(()=>{

	$('.hamburger-button').on('click', (e)=>{
		$(e.currentTarget).toggleClass('opened');
		$('#g-nav').slideToggle();
	});

	$('.local-nav .container > ul > li').on('mouseenter', (e)=>{
		if($(e.currentTarget).find('ul').length)
		$(e.currentTarget).addClass('active');
	});

	$('.local-nav .container > ul > li').on('mouseleave', (e)=>{
		if($(e.currentTarget).find('ul').length)
		$(e.currentTarget).removeClass('active');
	});	

});

$.event.add(window, "load", function(){	

  if($('.swiper-container').length){

    let swiper = new Swiper(".swiper-container",{
			spaceBetween: 0,
			loop: true,
			speed: 800,
			autoplay: {
				delay: 4000,
			},			
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			}
    });

  }

});


